import { Folder } from "../interfaces";

export const FOLDERS_MOCK: Folder[] = [
    {
        id: 0,
        name: 'Родитель 1'
    },
    {
        id: 1,
        parentId: 0,
        name: 'Ребёнок 1'
    },
    {
        id: 2,
        parentId: 0,
        name: 'Ребёнок 2'
    },
    {
        id: 3,
        parentId: 1,
        name: 'Внук'
    },
    {
        id: 4,
        name: 'Родитель 2'
    },
];