import React from "react";
import { TodoTypeText } from "../../interfaces";
import ReturnButton from "./ReturnButton/ReturnButton";
import TextEditor from "./TextEditor/TextEditor";

interface Props {
    todo: TodoTypeText | undefined
    onReturn: () => void
    onChange: (newValue: string) => void
}

const Editor = ({ todo, onReturn, onChange }: Props) => {
    if (todo === undefined)
        return (
            <ReturnButton onReturn={onReturn} />
        );

    return (
        <div className="layout__window">
            <div className="layout__header">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <ReturnButton onReturn={onReturn} />
                    {todo.title}
                </div>
                <div />
            </div>
            <div className="layout__container">
                <TextEditor
                    stateStr={todo.text}
                    onChange={onChange}
                />
            </div>
            <div className="layout__footer">

            </div>
        </div>
    )
}

export default Editor;