import { TodoType, TodoTypeText } from "../interfaces";

export const TODOS_MOCK: TodoTypeText[] = [
    {
        id: 0,
        type: TodoType.TEXT,
        title: 'Корневая заметка',
        text: 'Полный текст корневой заметки'
    },
    {
        id: 1,
        type: TodoType.TEXT,
        title: 'Заметка в папке',
        text: 'Полный текст заметки в папке',
        folderId: 0
    },
];