import {
    ContentState,
    EditorState,
    convertFromRaw,
} from 'draft-js';
import { isJsonString } from "./isJsonString";

export const getStateFromStr = (stateStr: string | undefined) => {
    if (!stateStr)
        return EditorState.createEmpty();

    if (isJsonString(stateStr))
        return EditorState.createWithContent(convertFromRaw(JSON.parse(stateStr)));

    return EditorState.createWithContent(ContentState.createFromText(stateStr));
}