import React, { useCallback, useMemo, useState, MouseEvent } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddFolderButton from "../AddFolderButton/AddFolderButton";
import RemoveFolderButton from "../RemoveFolderButton/RemoveFolderButton";
import FolderIcon from '@mui/icons-material/Folder';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from "@mui/material/IconButton";
import { Link } from 'react-router-dom';

interface Props {
    id: number
    name: string
    open: boolean
    onClick?: () => void
    onAddFolder: (name: string, parentId?: number) => void
    onRemoveFolder: (id: number) => void
}

const FolderRow = ({ id, name, open, onClick, onAddFolder, onRemoveFolder }: Props) => {
    const [showMore, setShowMore] = useState<boolean>(false);

    const icon = useMemo(() => (
        open
            ? <ArrowDropDownIcon className='explorer__icon' />
            : <ArrowRightIcon className='explorer__icon' />
    ), [open]);

    const handleRemoveFolder = useCallback(() => {
        onRemoveFolder(id);
    }, [onRemoveFolder, id]);

    const toggleShowMore = useCallback((e: MouseEvent) => {
        setShowMore((value) => !value);
        e.stopPropagation();
    }, [setShowMore]);

    return (
        <div
            className='folderRow'
            onClick={onClick}
        >
            {icon}
            <FolderIcon className='explorer__icon explorer__icon_folder' />
            <div className='folderRow__name'>
                {name}
            </div>
            <div>
                {showMore
                    ? (
                        <>
                            <RemoveFolderButton
                                name={name}
                                onRemoveFolder={handleRemoveFolder}
                            />
                            <AddFolderButton
                                onAddFolder={onAddFolder}
                                parentId={id}
                                parentName={name}
                            />
                        </>
                    )
                    : (
                        <IconButton
                            onClick={toggleShowMore}
                            size='small'
                        >
                            <MoreHorizIcon className='explorer__icon' />
                        </IconButton>
                    )
                }
            </div>
        </div>
    )
}

export default FolderRow;