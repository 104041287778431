import React, { useCallback } from "react";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
    name: string
    onRemoveFolder: () => void
}

const RemoveFolderButton = ({ name, onRemoveFolder }: IProps) => {
    const handleRemoveFolder = useCallback(() => {
        if (window.confirm(`Удалить папку "${name}"?`))
            onRemoveFolder();
    }, [name, onRemoveFolder]);

    return (
        <IconButton
            onClick={handleRemoveFolder}
            size='small'
        >
            <DeleteIcon className='explorer__icon explorer__icon_remove' />
        </IconButton>
    )
}

export default RemoveFolderButton;