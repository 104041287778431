import { EditorState } from "draft-js";
import { INLINE_STYLES } from "../constants/INLINE_STYLES";
import { StyleButton } from "../StyleButton/StyleButton";
import React from "react";

interface IInlineStyleContols {
    editorState: EditorState
    onToggle: (inlineStyle: string) => void
}

export const InlineStyleControls = (props: IInlineStyleContols) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <>
            {INLINE_STYLES.map((type) => (
                <StyleButton
                    onToggle={props.onToggle}
                    label={type.label}
                    style={type.style}
                    active={currentStyle.has(type.style)}
                    key={type.label}
                />
            ))}
        </>
    )
};