import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { usePersistedState } from "../../hooks";
import { Folder, TodoTypeText } from "../../interfaces";
import FolderRow from "./FolderRow/FolderRow";
import { FOLDERS_MOCK } from "../../mock/FOLDERS_MOCK";
import AddFolderButton from "./AddFolderButton/AddFolderButton";
import TodoRow from "./TodoRow/TodoRow";
import history from "../../history";

interface Props {
    todos: TodoTypeText[]
    setTodos: Dispatch<SetStateAction<TodoTypeText[]>>
    onSelectTodo: (id: number | null) => void
}

const Explorer = ({ todos, setTodos, onSelectTodo }: Props) => {
    const [folders, setFolders] = usePersistedState<Folder[]>('folders', FOLDERS_MOCK);
    const [openFolderIds, setOpenFolderIds] = useState<number[]>([]);

    const handleAddFolder = useCallback((name: string, parentId?: number) => {
        const id: number = (Math.max(...folders.map((todo) => todo.id)) || 0) + 1;
        const newFolder: Folder = {
            id,
            parentId,
            name
        };
        setFolders([...folders, newFolder]);
    }, [folders]);

    const handleRemoveFolder = useCallback((id: number) => {
        //todo: перенос todo из удалённой папки в родительскую
        const folderToRemoveIndex: number = folders.findIndex((f) => f.id === id);
        if (folderToRemoveIndex < 0)
            return;
        const parentId: number | undefined = folders[folderToRemoveIndex].parentId;
        setFolders(folders
            .filter((f) => f.id !== id)
            .map((f) => {
                if (f.parentId === id)
                    return {...f, parentId };
                return f;
            })
        );
        setTodos(todos
            .map((todo) => {
                if (todo.folderId === id)
                    return {...todo, folderId: parentId };
                return todo;
            })
        );
    }, [folders, setFolders, todos, setTodos]);

    const renderFolders = (id: number | undefined, isFirstLevel: boolean = false) => {
        const _folders: Folder[] = folders.filter((folder) => folder.parentId === id);
        const todosInFolder: TodoTypeText[] = todos.filter((todo) => todo.folderId === id);
        return (<>
            <div className={`folderContent ${isFirstLevel ? ' folderContent_firstLevel' : ''}`}>
                {_folders.map((folder) => {
                    const isFolderOpen: boolean = openFolderIds.includes(folder.id);
                    const handleClick = isFolderOpen
                            ? () => setOpenFolderIds((openFolderIds) => openFolderIds.filter((folderId) => folderId !== folder.id))
                            : () => setOpenFolderIds([...openFolderIds, folder.id]);
                    return (
                        <React.Fragment key={folder.id}>
                            <FolderRow
                                id={folder.id}
                                name={folder.name}
                                open={isFolderOpen}
                                onClick={handleClick}
                                onAddFolder={handleAddFolder}
                                onRemoveFolder={handleRemoveFolder}
                                key={folder.id}
                            />

                            {isFolderOpen
                                ? <>
                                    {renderFolders(folder.id)}
                                </>
                                : null
                            }
                        </React.Fragment>
                    )
                })}
                {todosInFolder.map((todo) => (
                    <TodoRow
                        todo={todo}
                        onSelect={() => {
                            onSelectTodo(todo.id);
                            history.push('/text-editor')
                        }}
                        key={todo.id}
                    />
                ))}
            </div>
        </>)
    };

    return (
        <div className="layout__window">
            <div className="layout__header">
                <div />
                <AddFolderButton
                    onAddFolder={handleAddFolder}
                />
            </div>
            <div className="layout__container">
                {renderFolders(undefined, true)}
            </div>
            <div className="layout__footer">

            </div>
        </div>
    )
}

export default Explorer;