import React from "react";
import { TodoTypeText } from "../../../interfaces";
import DescriptionIcon from '@mui/icons-material/Description';

interface Props {
    todo: TodoTypeText
    onSelect: () => void
}

const TodoRow = ({ todo, onSelect }: Props) => {
    return (
        <div
            className='folderRow'
            style={{ paddingLeft: '1.6rem' }}
            onClick={onSelect}
        >
            <DescriptionIcon className='explorer__icon' />
            <div className='folderRow__name'>
                {todo.title}
            </div>
            <div />
        </div>
    )
}

export default TodoRow;