import React, { useCallback, useMemo, useState } from "react";
import {
    Editor,
    EditorState,
    RichUtils,
    convertToRaw,
    ContentState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { getStateFromStr } from "./utils/getStateFromStr";
import { BlockStyleControls } from "./BlockStyleControls/BlockStyleControls";
import { InlineStyleControls } from "./InlineStyleControls/InlineStyleControls";
import { getBlockStyle } from "./utils/getBlockStyle";

interface Props {
    stateStr: string
    onChange: (stateStr: string) => void
}

const TextEditor = ({
    stateStr,
    onChange
}: Props) => {
    const [editorState, setEditorState] = useState<EditorState>(() => (
        getStateFromStr(stateStr)
    ));

    const saved: boolean = useMemo(() => {
        const contentState: ContentState = editorState.getCurrentContent();
        const rawContent: string = JSON.stringify(convertToRaw(contentState));
        return stateStr === rawContent;
    }, [editorState, stateStr]);

    const handleSave = useCallback((_editorState) => {
        const contentState: ContentState = _editorState.getCurrentContent();
        const rawContent: string = JSON.stringify(convertToRaw(contentState));
        // if (rawContent !== stateStr)
            onChange(rawContent);
    }, [editorState]);

    const handleChange = useCallback((_editorState: EditorState) => {
        setEditorState(_editorState);
        handleSave(_editorState);
    }, [setEditorState, handleSave]);

    const toggleBlockType = (blockType: string) => {
        handleChange(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        )
    };

    const toggleInlineStyle = (inlineStyle: string) => {
        handleChange(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        )
    };

    return (
        <>
            <div style={{ color: saved ? 'lightgray' : 'red', textAlign: 'center', width: '100%' }}>
                {saved ? 'Сохранено' : 'Не сохранено'}
            </div>

            <Editor
                editorState={editorState}
                blockStyleFn={getBlockStyle}
                onChange={handleChange}
                placeholder='Введите текст'
                spellCheck={false}
                autoCapitalize={'none'}
                autoComplete={'off'}
                autoCorrect={'off'}
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={toggleBlockType}
                />
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={toggleInlineStyle}
                />
            </div>
        </>
    )
}

export default TextEditor;