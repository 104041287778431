import Button from "@mui/material/Button";
import React from "react";

interface IStyleButton {
    onToggle: (inlineStyle: string) => void
    label: string
    style: string
    active: boolean
}

export const StyleButton = ({ onToggle, label, style, active }: IStyleButton) => (
    <Button
        onMouseDown={(e) => {
            e.preventDefault();
            onToggle(style);
        }}
        color={active ? 'secondary' : 'primary'}
        style={{ margin: 0, minWidth: 0 }}
    >
        {label}
    </Button>
);