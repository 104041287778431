export interface Folder {
    id: number
    parentId?: number
    name: string
}








export enum TodoType {
    TEXT = 'TEXT',
    LIST = 'LIST',
    TABLE = 'TABLE'
}

// export interface TodoInfo {
//     id: number
//     importance?: number
//     completed?: boolean
//     folderId?: number
// }

export interface TodoTypeText {
    id: number
    type: TodoType.TEXT
    title: string
    text: string
    folderId?: number
}

// export interface TodoTypeList {
//     id: number
//     type: TodoType.LIST
//     title: string
//     list: string[] //todo: interface
//     folderId?: number
// }
//
// export interface TodoTypeTable {
//     id: number
//     type: TodoType.TABLE
//     title: string
//     data: string[][] //todo: interface
//     folderId?: number
// }