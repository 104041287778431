import React from 'react';
import ReactDOM from 'react-dom';
import Index from "./components/Index";
import { Router } from 'react-router-dom';
import history from "./history";

import './css/index.css';
import './css/App.css';

ReactDOM.render(
  <React.StrictMode>
      <Router history={history}>
            <Index />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

if ('serviceWorker' in navigator) {
    // Ожидаем полной загрузки страницы
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
    })
}