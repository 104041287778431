import { useCallback, useEffect, useState } from "react";
import { set, get, createStore } from "idb-keyval";
import { VERSION } from "./constants";
const db = createStore('appData', VERSION);

export const usePersistedState = <TState>(keyToPersistWith: string, defaultState: TState) => {
    const [state, setState] = useState<TState>(defaultState);

    useEffect(() => {
        get<TState>(keyToPersistWith, db).then(retrievedState =>
            // If a value is retrieved then use it; otherwise default to defaultValue
            setState(retrievedState ?? defaultState));
    }, [keyToPersistWith, setState, defaultState]);

    type SetTState = (prevState: TState) => TState;

    const setPersistedValue = useCallback((value: TState | SetTState) => {
        if (typeof value === 'function') {
            const _newValue: TState = (value as SetTState)(state);
            setState(_newValue);
            set(keyToPersistWith, _newValue, db);
            return;
        }
        setState(value);
        set(keyToPersistWith, value, db);
    }, [keyToPersistWith, setState, state]);

    return [state, setPersistedValue] as const;
}
