import React, { useCallback, useMemo, useState } from "react";
import Explorer from "./Explorer/Explorer";
import Editor from "./Editor/Editor";
import { usePersistedState } from "../hooks";
import { TodoTypeText } from "../interfaces";
import { TODOS_MOCK } from "../mock/TODOS_MOCK";
import { Switch, Route } from 'react-router-dom';
import history from "../history";

const Index = () => {
    const [todos, setTodos] = usePersistedState<TodoTypeText[]>('todos', TODOS_MOCK);
    const [currentTodoId, setCurrentTodoId] = useState<number | null>(null);

    const currentTodo: TodoTypeText | undefined = useMemo(() => (
        todos.find((todo) => todo.id === currentTodoId)
    ), [todos, currentTodoId]);

    const handleSelectTodo = useCallback((id: number | null) => {
        setCurrentTodoId(id);
    }, [setCurrentTodoId]);

    const handleUnSelectTodo = useCallback(() => {
        setCurrentTodoId(null);
        history.push('/');
    }, [setCurrentTodoId]);

    const handleUpdateTodo = useCallback((id: number, newValue: string) => {
        setTodos((todos) => todos.map((todo) => {
            if (todo.id === id)
                return {
                    ...todo,
                    text: newValue
                }
            return todo;
        }))
    }, [setTodos]);

    return (
        <Switch>
            <Route
                exact
                path='/'
                render={() => (
                    <Explorer
                        todos={todos}
                        setTodos={setTodos}
                        onSelectTodo={handleSelectTodo}
                    />
                )}
            />
            <Route
                exact
                path='/text-editor'
                render={() => (
                    <Editor
                        todo={currentTodo}
                        onReturn={handleUnSelectTodo}
                        onChange={currentTodo
                            ? (newValue) => handleUpdateTodo(currentTodo.id, newValue)
                            : () => {}
                        }
                    />
                )}
            />
        </Switch>
    );
}

export default Index;