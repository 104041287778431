import { EditorState } from "draft-js";
import { BLOCK_TYPES } from "../constants/BLOCK_TYPES";
import { StyleButton } from "../StyleButton/StyleButton";
import React from "react";

interface IBlockStyleControls {
    editorState: EditorState
    onToggle: (inlineStyle: string) => void
}

export const BlockStyleControls = ({ editorState, onToggle }: IBlockStyleControls) => {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <>
            {BLOCK_TYPES.map((type) => (
                <StyleButton
                    onToggle={onToggle}
                    label={type.label}
                    style={type.style}
                    active={type.style === blockType}
                    key={type.label}
                />
            ))}
        </>
    );
};