import React, { useCallback, useState } from "react";
import IconButton from '@mui/material/IconButton';
import AddFolderDialog from "./AddFolderDialog/AddFolderDialog";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

interface IProps {
    onAddFolder: (name: string, parentId?: number) => void
    parentId?: number
    parentName?: string
}

const AddFolderButton = ({ onAddFolder, parentId, parentName }: IProps) => {
    const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false);

    const handleOpenRenameDialog = () => {
        setOpenRenameDialog(true);
    }

    const handleCloseRenameDialog = () => {
        setOpenRenameDialog(false);
    }

    const handleAddFolder = useCallback((name: string) => {
        onAddFolder(name, parentId);
    }, [onAddFolder, parentId]);

    return (<>
        <IconButton
            onClick={handleOpenRenameDialog}
            size='small'
        >
            <CreateNewFolderIcon className='explorer__icon' />
        </IconButton>

        {openRenameDialog && (
            <AddFolderDialog
                parentName={parentName}
                open={openRenameDialog}
                onClose={handleCloseRenameDialog}
                onEnter={handleAddFolder}
            />
        )}
    </>)
}

export default AddFolderButton;