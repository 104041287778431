import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
    onReturn: () => void
}

const ReturnButton = ({ onReturn }: Props) => {
    return (
        <IconButton
            onClick={onReturn}
            size='small'
        >
            <ArrowBackIcon className='explorer__icon' />
        </IconButton>
    )
}

export default ReturnButton;